import React from 'react';
import styled from 'styled-components';

const StyledH2 = styled.h2`
  font-family: ${({ theme }) => theme.font.family.bold};
  font-weight: 600;
  font-size: 10rem;
  text-align: center;
  margin: 15rem 0 5rem;
`;

const StyledP = styled.p`
  font-size: 6rem;
  text-align: center;
  margin: 5rem 0 15rem;
`;

const NotFound = () => (
  <>
    <StyledH2>404</StyledH2>
    <StyledP>Not found</StyledP>
  </>
);

export default NotFound;
